import "./topbar.css"
import { Search } from "@mui/icons-material"

export default function Topbar() {
    return (
        <div className="topbarContainer">
            <div className="topbarLeft">
            </div>
            <div className="topbarCenter">
                <span className="logo">Statusübersicht LK Leer</span>
            </div>
            <div className="topbarRight">
                <div className="topbarLinks">
                </div>
                <div className="topbarIcons">

                </div>
            </div>
        </div>
    )
}