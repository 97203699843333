import "./main.css"
import styled from "styled-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import GridLayout from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

const layout = [
    { i:"10 Borkum", x:0, y:0, w:1, h:1},
    { i:"11 Hesel", x:1, y:0, w:1, h:1},
    { i:"12 Uplengen", x:2, y:0, w:1, h:1},
    { i:"13 Leer", x:3, y:0, w:1, h:1},
    { i:"14 Moormerland", x:4, y:0, w:1, h:1},
    { i:"15 Jümme", x:5, y:0, w:1, h:1},
    { i:"20 Bunde", x:0, y:0, w:1, h:1},
    { i:"21 Jemgum", x:1, y:1, w:1, h:1},
    { i:"22", x:2, y:1, w:1, h:1},
    { i:"23", x:3, y:1, w:1, h:1},
    { i:"24", x:4, y:1, w:1, h:1},
    { i:"25 Westoverledingen", x:5, y:1, w:1, h:1},
    { i:"Rettungsdienst", x:0, y:2, w:1, h:1},
    { i:"80 Kreisfeuerwehr", x:1, y:2, w:1, h:1},
    { i:"99 Kreisführung", x:2, y:2, w:1, h:1},
];

const gem13 = [
    {i:"1 Leer", x:0, y:0, w:1, h:1},
    {i:"2 Bingum", x:1, y:0, w:1, h:0.25},
    {i:"3 Heisfelde", x:1, y:1, w:1, h:0.25},
    {i:"4 Loga", x:1, y:2, w:1, h:0.41},
    {i:"6 Nüttermoor", x:1, y:3, w:1, h:0.25},
];

const getLayouts = () => {
    const savedLayouts = localStorage.getItem("grid-layout");
  
    return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
  };
  
const GridItemWrapper = styled.div`
  background: black;
  color: white;
`;

const GridItemContent = styled.div`
  padding: 5px;
`;

const Root = styled.div`
  padding: 10px;
`;

export default function Main() {
    const handleLayoutChange = (layout, layouts) => {
        localStorage.setItem("grid-layout", JSON.stringify(layouts));
    };
    return(
        <div>
            <ResponsiveGridLayout layouts={getLayouts()} 
                breakpoints= {{lg: 1200, md:996, sm: 768, xs: 480, xxs: 0}}
                cols={{lg:6, md:5, sm:3, xs: 2, xxs:1}} 
                rowHeight={600} width={1500} /*onLayoutChange={handleLayoutChange}*/>
                <GridItemWrapper key="10 Borkum">
                    <GridItemContent>10 Borkum</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="11 Hesel">
                    <GridItemContent>11 Hesel</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="12 Uplengen">
                    <GridItemContent>12 Uplengen</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="13 Leer">
                    <GridItemContent>
                        <GridLayout layout={gem13} cols={2} rowHeight={500} width={240}>
                            <GridItemWrapper key="1 Leer">
                                <GridItemContent key="13-01-1" className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus2">2</div>
                                        <div className="ressourceName">13-01-1</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus2">2</div>
                                        <div className="ressourceName">13-02-1</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent>Leer</GridItemContent>
                                <GridItemContent key="13-03-1" className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus1">1</div>
                                        <div className="ressourceName">13-03-1</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent key="13-04-1" className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus3">3</div>
                                        <div className="ressourceName">13-04-1</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent key="13-04-1" className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus4">4</div>
                                        <div className="ressourceName">13-11-7</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent key="13-04-1" className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus6">6</div>
                                        <div className="ressourceName">13-17-1</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent className="ressourceContainer">13-30-1</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-37-1</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-42-1</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-47-1</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-48-1</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-62-1</GridItemContent>
                            </GridItemWrapper>
                            <GridItemWrapper key="2 Bingum">
                                <GridItemContent>Bingum</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-17-2</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-42-2</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-45-2</GridItemContent>
                            </GridItemWrapper>
                            <GridItemWrapper key="3 Heisfelde">
                                <GridItemContent>Heisfelde</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-17-2</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-42-2</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-43-2</GridItemContent>
                            </GridItemWrapper>
                            <GridItemWrapper key="4 Loga">
                                <GridItemContent>Loga</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-17-7</GridItemContent>
                                <GridItemContent key="13-17-7" className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus2">2</div>
                                        <div className="ressourceName">13-17-7</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent className="ressourceContainer">13-24-4</GridItemContent>
                                <GridItemContent key="13-27-7" className="ressourceContainer">
                                    <div className="ressource">
                                        <div className="ressourceStatus2">2</div>
                                        <div className="ressourceName">13-27-7</div>
                                    </div>
                                </GridItemContent>
                                <GridItemContent className="ressourceContainer">13-45-4</GridItemContent>
                            </GridItemWrapper>
                            <GridItemWrapper key="6 Nüttermoor">
                                <GridItemContent>Nüttermoor</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-17-2</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-42-2</GridItemContent>
                                <GridItemContent className="ressourceContainer">13-43-2</GridItemContent>
                            </GridItemWrapper>
                        </GridLayout>
                    </GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="14 Moormerland">
                    <GridItemContent>14 Moormerland</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="15 Jümme">
                    <GridItemContent>15 Jümme</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="20 Bunde">
                    <GridItemContent>20 Bunde</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="21 Jemgum">
                    <GridItemContent>21 Jemgum</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="22">
                    <GridItemContent>22</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="23">
                    <GridItemContent>23</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="24">
                    <GridItemContent>24</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="25 Westoverledingen">
                    <GridItemContent>25 Westoverledingen</GridItemContent>
                </GridItemWrapper>

                <GridItemWrapper key="Rettungsdienst">
                    <GridItemContent>Rettungsdienst</GridItemContent>
                </GridItemWrapper>
                <GridItemWrapper key="Kreisfeuerwehr">
                    <GridItemContent>Kreisfeuerwehr</GridItemContent>
                </GridItemWrapper>
            </ResponsiveGridLayout>
        </div>
    )
}