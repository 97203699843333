import Topbar from "../../components/topbar/Topbar";
import Main from "../../components/main/Main";

export default function Home() {
    return (
        <>
            <Topbar/>
            <div className="mainContainer">
                <Main/>
            </div>
        </>
    )
}